import { matchPath } from 'react-router-dom';

// support languages in the URL prefix
// e.g. /fr-FR/path, or /en/path, or /path
export const routePatterns = ['/:sitecoreRoute*'];

// use react-router-dom to find the route matching the incoming URL
// then return its match params
// we are using .some() as a way to loop with a short circuit (so that we stop evaluating route patterns after the first match)
export const matchRoute = (route) => {
  if (!route) {
    return null;
  }

  let result = null;

  routePatterns.some((pattern) => {
    const match = matchPath(route, { path: pattern });
    if (match && match.params) {
      match.params.sitecoreRoute = decodeURI(match.params.sitecoreRoute || '');
      result = match;
      return true;
    }

    return false;
  });

  return result;
};

// When accessing `__RouterContext` from react-router, the location and history
// properties of the context are correct, but the route parameters are not
// initialized. Therefore, we call the `matchRoute` function on the router context
// path to get the matched route parameters (i.e. `:sitecoreRoute`, `:lang`).
// This will likely change in a future version of React Router, but this works
// with the current version.
export const resolveRouterRoute = (routerContext, appState) => {
  const matchedRoute = matchRoute(routerContext.location.pathname);
  matchedRoute.sitecoreRoute = resolveItemRoute(
    matchedRoute.params.sitecoreRoute,
    appState.routeMap,
    {
      hostDomain:
        typeof window !== 'undefined'
          ? window?.location?.hostname
          : appState.hostDomain,
      frenchDomain: appState.frenchDomain,
      englishDomain: appState.englishDomain,
    }
  );
  return matchedRoute;
};

export const cleanupUrl = (route) => {
  route = route || '';
  return route
    .replace(/\/en$/, '')
    .replace(/\/fr$/, '')
    .replace('/en/', '/')
    .replace('/fr/', '/')
    .replace('/', '')
    .toLocaleLowerCase();
};

export const resolveRouteLanguage = (
  route,
  routeMap,
  { hostDomain, frenchDomain, englishDomain }
) => {
  let routeName = cleanupUrl(route);
  let urlLang;
  let domainLang;

  if (
    routeName === '' ||
    routeName.localeCompare('404') === 0 ||
    routeName.localeCompare('500') === 0
  ) {
    return '';
  }

  if (
    (process.env.FRENCH_DOMAIN &&
      process.env.FRENCH_DOMAIN.indexOf(hostDomain) >= 0) ||
    frenchDomain?.indexOf(hostDomain) >= 0
  ) {
    domainLang = 'fr';
  }
  if (
    (process.env.ENGLISH_DOMAIN &&
      process.env.ENGLISH_DOMAIN.indexOf(hostDomain) >= 0) ||
    englishDomain?.indexOf(hostDomain) >= 0
  ) {
    domainLang = 'en';
  }

  if (isEnglishHomepageRoute(routeName)) {
    urlLang = 'en';
  }
  if (isFrenchHomepageRoute(routeName)) {
    urlLang = 'fr';
  }

  if (!routeMap) {
    urlLang = '';
  }

  for (let lang in routeMap) {
    if (routeMap.hasOwnProperty(lang)) {
      const routeMapItem = routeMap[lang];
      const routeItem = routeMapItem.find((item) => {
        const url = cleanupUrl(item?.fields?.url?.value?.href);
        const routeNameWithoutQuery = getRouteNameWithoutQuery(routeName);
        return (
          url.localeCompare(routeNameWithoutQuery.toLocaleLowerCase(), lang) ===
          0
        );
      });
      if (routeItem) {
        urlLang = lang;
      }
    }
  }

  if (domainLang) {
    return domainLang;
  }

  return urlLang;
};
export const resolveItemRoute = (route, routeMap) => {
  let routeName = cleanupUrl(route);
  let routeItem;

  if (
    routeName.localeCompare('404') === 0 ||
    routeName.localeCompare('500') === 0
  ) {
    return routeName;
  }
  if (isHomepageRoute(routeName)) {
    return '/';
  }
  if (!routeMap) {
    return routeName;
  }
  let itemRoute = routeName;
  for (let lang in routeMap) {
    if (routeMap.hasOwnProperty(lang)) {
      const routeMapItem = routeMap[lang];
      routeItem = routeMapItem.find((item) => {
        if (routeName === '') {
          return item.name.toLowerCase() === 'home';
        }
        const url = cleanupUrl(item?.fields?.url?.value?.href);
        const routeNameWithoutQuery = getRouteNameWithoutQuery(routeName);
        return (
          url.localeCompare(routeNameWithoutQuery.toLocaleLowerCase(), lang) ===
          0
        );
      });
      if (routeItem) {
        itemRoute = routeItem.name.toLowerCase();
        if (routeItem.name.toLowerCase() === 'home') {
          itemRoute = '/';
        }
      }
    }
  }

  return itemRoute;
};

export const resolveRouteForLocale = (
  currentRoute,
  state,
  newLanguage,
  currentLanguage
) => {
  let routeName = cleanupUrl(currentRoute);
  //special cases for error pages
  if (
    routeName.localeCompare('404') === 0 ||
    routeName.localeCompare('500') === 0
  ) {
    return routeName;
  }

  //special cases for home page
  if (isHomepageRoute(routeName)) {
    return newLanguage === 'en' ? 'home' : 'accueil';
  }

  let routeMap = state.routeMap;
  if (!routeMap || !routeMap[currentLanguage]) {
    return routeName;
  }
  const currentRouteItem = routeMap[currentLanguage].find((item) => {
    if (routeName === '') {
      return item?.name?.toLocaleLowerCase().localeCompare('home') === 0;
    }
    const url = cleanupUrl(item.fields.url.value.href);
    const routeNameWithoutQuery = getRouteNameWithoutQuery(routeName);
    return (
      url.localeCompare(
        routeNameWithoutQuery?.toLocaleLowerCase(),
        currentLanguage
      ) === 0
    );
  });
  const currentRouteName = currentRouteItem?.name;
  const newRouteItem = routeMap?.[newLanguage]?.find((item) => {
    const routeNameWithoutQuery = getRouteNameWithoutQuery(currentRouteName);
    return (
      item?.name
        ?.toLocaleLowerCase()
        ?.localeCompare(routeNameWithoutQuery?.toLocaleLowerCase()) === 0
    );
  });
  const newUrl = cleanupUrl(newRouteItem?.fields?.url?.value?.href);
  let resolvedRoute = newRouteItem ? newUrl : routeName;
  if (!resolvedRoute) {
    resolvedRoute = newLanguage === 'en' ? 'home' : 'accueil';
  }

  return resolvedRoute;
};

// Checks if the first word in the route name represents the homepage in either language.
//
// The reason for checking the first word instead of whether it exists at all is to account
// for the words "home" or "accueil" appearing somewhere in the query string and producing a false positive.
//
// Examples:
//   home  --> true
//   home?123  --> true
//   faq?q=home  --> false
function isHomepageRoute(routeName) {
  return isEnglishHomepageRoute(routeName) || isFrenchHomepageRoute(routeName);
}

function isEnglishHomepageRoute(routeName) {
  return routeName?.indexOf('home') === 0;
}

function isFrenchHomepageRoute(routeName) {
  return routeName?.indexOf('accueil') === 0;
}

// Return the base route name from a full URL path.
// Eg. home?q=123 ---> home
function getRouteNameWithoutQuery(routeName) {
  return routeName?.indexOf('?') >= 0 ? routeName?.split('?')[0] : routeName;
}
