import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import config from './temp/config';

/**
 * Initializes the i18next library to provide a translation dictionary to the app.
 * If your app is not multilingual, this file and references to it can be removed.
 * Elsewhere in the app to use the dictionary `import { t } from 'i18next'; ... t('key')`
 * @param {string} language Optional, the initial language.
 * @param {*} dictionary Optional, the dictionary to load. Only used for SSR; otherwise, the dictionary is loaded via JSS dictionary service.
 */
export default function i18nInit(language, dictionary, onPhraseProcessed) {
  const options = {
    debug: false,
    lng: language,
    fallbackLng: false, // fallback to keys
    load: 'currentOnly', // e.g. don't load 'es' when requesting 'es-MX' -- Sitecore config should handle this
    useCookie: false, // using URLs and Sitecore to store language context, don't need a cookie

    interpolation: {
      escapeValue: false, // not needed for react
    },
    react: {
      useSuspense: false,
    },
    partialBundledLanguages: true,
  };

  if (dictionary && typeof window === 'undefined') {
    // if we got dictionary passed _and_ we're in a server environment, that means we're in a SSR context
    // so we do not want a backend to fetch phrases, because we already know all possible keys.
    // In this scenario, we also want to attach a tracker/capture processor so that we can
    // pass translated key/value pairs back to the client via serialized SSR state.
    options.resources = {};
    options.resources[language] = {
      translation: dictionary,
    };

    const captureTranslatedPhrasesPostProcessor =
      initCaptureTranslatedPhrasesPostProcessor(onPhraseProcessed);
    options.postProcess = [captureTranslatedPhrasesPostProcessor.name];

    return i18n
      .use(initReactI18next)
      .use(captureTranslatedPhrasesPostProcessor)
      .init(options);
  } else {
    // We're running client-side, so setup a fetch backend to get translation data from the Sitecore dictionary API.
    const dictionaryServicePath = `${config.sitecoreApiHost}/sitecore/api/jss/dictionary/${config.jssAppName}/{{lng}}?sc_apikey=${config.sitecoreApiKey}`;

    options.backend = {
      loadPath: dictionaryServicePath,
      parse: (data) => {
        const parsedData = JSON.parse(data);
        if (parsedData.phrases) {
          return parsedData.phrases;
        }
        return parsedData;
      },
    };

    // However, we may also have dictionary data deserialized from SSR state, so we can initialize with that
    // if it is present.
    if (dictionary) {
      options.resources = {};
      options.resources[language] = {
        translation: dictionary,
      };
    }

    // For higher performance (but less simplicity), consider adding the i18n chained backend to a local cache
    // option like the local storage backend.

    return (
      i18n
        // .use(fetchBackend)
        .use(initReactI18next)
        .init(options)
    );
  }
}

function initCaptureTranslatedPhrasesPostProcessor(onProcess) {
  return {
    // i18next expects the type value to be 'postProcessor'
    type: 'postProcessor',
    // arbitrary name for the processor, but must match the value used in `i18n.options.postProcess`
    name: 'captureTranslatedPhrases',
    // i18next post processors must implement a `process` function that returns the processed value.
    // In our case, we don't modify the value, we're only interested in capturing it.
    process: function (value, key, options, translator) {
      // if the onProcess function is provided and valid, call it with the key/value
      // pair that is being translated
      if (onProcess && typeof onProcess === 'function') {
        onProcess(key, value);
      }
      /* return manipulated value */
      return value;
    },
  };
}
