import React from 'react';
import Helmet from 'react-helmet';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import styled from 'styled-components';
import { media, BreakpointProvider } from '@honda-canada/honda-sitecore-react';

const MainContainer = styled.main`
  margin-top: 74px;

  ${media.tablet} {
    margin-top: 50px;
  }
`;

export function Content({ route, context }) {
  const pageTitle =
    (route.fields && route.fields.pageTitle && route.fields.pageTitle.value) ||
    'Page';
  const currentLanguage =
    (route.fields && route.fields.itemLanguage) || context.language;

  return (
    <BreakpointProvider>
      <Helmet>
        <title>{pageTitle || 'Honda Canada Foundation'}</title>
      </Helmet>
      {/* root placeholder for the app, which we add components to using route data */}
      <Placeholder name="hcf-metadata" rendering={route} />
      <MainContainer>
        <Placeholder
          name="hcf-main"
          rendering={route}
          params={{
            currentLanguage,
          }}
        />
      </MainContainer>
      <Placeholder name="hcf-footer" rendering={route} />
    </BreakpointProvider>
  );
}
