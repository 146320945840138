import {
  Carousel,
  CarouselSlide,
  Hero,
  HorizontalCards,
  NavigationLayout,
  StaticContent,
  IconGrid,
  AnimatedContent,
  InfoGraphicContainer,
  SpeedometerGraphic,
  BarChartGraphic,
  ComponentHeader,
  TabTextContent,
  TabImageContent,
  TabVideoContent,
  Tabs,
  SourceLink,
  InfoGraphicsText,
  CardGridContainer,
  CardGridItem,
  DonutChartGraphic,
  ImageGraphic,
  StaticNumberGraphic,
  TextTitle,
  ChartTitle,
  FAQ,
  Footer,
  Timeline,
  MetaTags,
  FreeFormText,
} from '@honda-canada/honda-sitecore-react';

const components = new Map();
components.set('Hero', Hero);
components.set('HorizontalCards', HorizontalCards);
components.set('Carousel', Carousel);
components.set('CarouselSlide', CarouselSlide);
components.set('PrimaryNav', NavigationLayout);
components.set('StaticContent', StaticContent);
components.set('IconGrid', IconGrid);
components.set('AnimatedContent', AnimatedContent);
components.set('BarChart', BarChartGraphic);
components.set('InfoGraphicSpeedometer', SpeedometerGraphic);
components.set('InfoGraphicText', InfoGraphicsText);
components.set('InfoGraphicSource', SourceLink);
components.set('InfoGraphicContainer', InfoGraphicContainer);
components.set('InfoGraphicHeader', ComponentHeader);
components.set('Tabs', Tabs);
components.set('TabTextContent', TabTextContent);
components.set('TabImageContent', TabImageContent);
components.set('TabVideoContent', TabVideoContent);
components.set('CardGridContainer', CardGridContainer);
components.set('CardGridItem', CardGridItem);
components.set('CardGridHeader', ComponentHeader);
components.set('InfoGraphicDonutChart', DonutChartGraphic);
components.set('InfoGraphicImageChart', ImageGraphic);
components.set('InfoGraphicBarChart', BarChartGraphic);
components.set('InfoGraphicStaticNumberChart', StaticNumberGraphic);
components.set('InfoGraphicTextTitle', TextTitle);
components.set('InfoGraphicChartTitle', ChartTitle);
components.set('FaqContent', FAQ);
components.set('FooterNav', Footer);
components.set('TimelineContent', Timeline);
components.set('MetaTags', MetaTags);
components.set('FreeFormText', FreeFormText);

export default function componentFactory(componentName) {
  return components.get(componentName);
}
