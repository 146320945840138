import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import {
  VisitorIdentification,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { routePatterns } from './lib/routerUtils';
import { SitecoreStyle } from './globalStyles';
import { LayoutBodyFactory } from './components/LayoutBodies/LayoutBodyFactory';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { AppStateContext } from './lib/AppStateProvider';
import { resolveRouteForLocale } from './lib/routerUtils';

/*
  APP LAYOUT
  This is where the app's HTML "shell" should be defined.

  All routes share this root layout by default,
  but components added to inner placeholders are route-specific.
*/

function Layout({ sitecoreContext }) {
  const { appState } = useContext(AppStateContext);

  if (!appState?.routeData) {
    return null;
  }

  const { context, route } = appState?.routeData?.sitecore || {};
  const siteLogo = route?.fields?.siteLogo || {};
  const currentLanguage = route?.fields?.itemLanguage || context?.language;

  const languageToggleOptions = [
    {
      languageCode: 'en',
      href: appState?.englishDomain
        ? `${appState?.englishDomain}/${resolveRouteForLocale(
            appState?.routePath,
            appState,
            'en',
            currentLanguage
          )}`
        : `/${resolveRouteForLocale(
            appState?.routePath,
            appState,
            'en',
            currentLanguage
          )}`,
      text: 'EN',
    },
    {
      languageCode: 'fr',
      href: appState.frenchDomain
        ? `${appState.frenchDomain}/${resolveRouteForLocale(
            appState?.routePath,
            appState,
            'fr',
            currentLanguage
          )}`
        : `/${resolveRouteForLocale(
            appState?.routePath,
            appState,
            'fr',
            currentLanguage
          )}`,
      text: 'FR',
    },
  ];

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet />
      {/*
          VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
          If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
          For XM (CMS-only) apps, this should be removed.

          VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
        */}
      <VisitorIdentification />
      <Placeholder
        name="hcf-header"
        rendering={route}
        params={{
          currentSite: route ? route?.displayName : '',
          siteOwner: context?.site?.name,
          siteLogo,
          currentLanguage,
          languageToggleOptions,
        }}
      />
      <Switch>
        {routePatterns.map((routePattern) => (
          <Route
            key={routePattern}
            path={routePattern}
            render={() => <LayoutBodyFactory appState={appState} />}
          />
        ))}
      </Switch>
      <SitecoreStyle sitecoreContext={sitecoreContext} />
    </React.Fragment>
  );
}

export default withSitecoreContext()(Layout);
