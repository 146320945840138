import HelveticaNeueLTEOT from '../assets/fonts/HelveticaNeueLT-Light.eot';
import HelveticaNeueLTWOFF2 from '../assets/fonts/HelveticaNeueLT-Light.woff2';
import HelveticaNeueLTWOFF from '../assets/fonts/HelveticaNeueLT-Light.woff';
import HelveticaNeueLTTTF from '../assets/fonts/HelveticaNeueLT-Light.ttf';

import HelveticaNeueLTRomanEOT from '../assets/fonts/HelveticaNeueLT-Roman.eot';
import HelveticaNeueLTRomanWOFF2 from '../assets/fonts/HelveticaNeueLT-Roman.woff2';
import HelveticaNeueLTRomanWOFF from '../assets/fonts/HelveticaNeueLT-Roman.woff';
import HelveticaNeueLTRomanTTF from '../assets/fonts/HelveticaNeueLT-Roman.ttf';

import HelveticaNeueLTLightEOT from '../assets/fonts/HelveticaNeueLT-Light.eot';
import HelveticaNeueLTLightWOFF2 from '../assets/fonts/HelveticaNeueLT-Light.woff2';
import HelveticaNeueLTLightWOFF from '../assets/fonts/HelveticaNeueLT-Light.woff';
import HelveticaNeueLTLightTTF from '../assets/fonts/HelveticaNeueLT-Light.ttf';

import HelveticaNeueLTBoldEOT from '../assets/fonts/HelveticaNeueLT-Bold.eot';
import HelveticaNeueLTBoldWOFF2 from '../assets/fonts/HelveticaNeueLT-Bold.woff2';
import HelveticaNeueLTBoldWOFF from '../assets/fonts/HelveticaNeueLT-Bold.woff';
import HelveticaNeueLTBoldTTF from '../assets/fonts/HelveticaNeueLT-Bold.ttf';

import NationalWebEOT from '../assets/fonts/NationalWeb-Light.eot';
import NationalWebWOFF2 from '../assets/fonts/NationalWeb-Light.woff2';
import NationalWebWOFF from '../assets/fonts/NationalWeb-Light.woff';
import NationalWebTTF from '../assets/fonts/NationalWeb-Light.ttf';

import NationalWebLFRegularEOT from '../assets/fonts/NationalWebLF-Regular.eot';
import NationalWebLFRegularWOFF from '../assets/fonts/NationalWebLF-Regular.woff';
import NationalWebLFRegularTTF from '../assets/fonts/NationalWebLF-Regular.ttf';

import NationalWebLFSemiboldEOT from '../assets/fonts/NationalWebLF-Semibold.eot';
import NationalWebLFSemiboldWOFF from '../assets/fonts/NationalWebLF-Semibold.woff';
import NationalWebLFSemiboldTTF from '../assets/fonts/NationalWebLF-Semibold.ttf';

import NationalWebThinEOT from '../assets/fonts/NationalWeb-Thin.eot';
import NationalWebThinWOFF from '../assets/fonts/NationalWeb-Thin.woff';

import NationalWebBoldEOT from '../assets/fonts/NationalWeb-Bold.eot';
import NationalWebBoldWOFF from '../assets/fonts/NationalWeb-Bold.woff';
import NationalWebBoldTTF from '../assets/fonts/NationalWeb-Bold.ttf';

import hondaIconsEOT from '../assets/icons/honda-icons.eot';
import hondaIconsWOFF from '../assets/icons/honda-icons.woff';
import hondaIconsTTF from '../assets/icons/honda-icons.ttf';

import MaterialIconsEOT from '../assets/icons/MaterialIcons-Regular.eot';
import MaterialIconsWOFF2 from '../assets/icons/MaterialIcons-Regular.woff2';
import MaterialIconsWOFF from '../assets/icons/MaterialIcons-Regular.woff';
import MaterialIconsTTF from '../assets/icons/MaterialIcons-Regular.ttf';

export const fonts = () => `
  @font-face {
    font-family: 'HelveticaNeueLT-Light';
    src: local('HelveticaNeueLT-Light'),
        url('${HelveticaNeueLTWOFF2}') format('woff2'),
        url('${HelveticaNeueLTWOFF}') format('woff'),
        url('${HelveticaNeueLTTTF}') format('truetype'),
        url('${HelveticaNeueLTEOT}') format('embedded-opentype');
    font-display: fallback;
  }

  @font-face {
    font-family: 'national';
    src: local('national'),
      url('${NationalWebEOT}') format('embedded-opentype'),
      url('${NationalWebWOFF2}') format('woff2'),
      url('${NationalWebWOFF}') format('woff'),
      url('${NationalWebTTF}') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'national';
    src: local('national'),
      url('${NationalWebThinEOT}') format('embedded-opentype'),
      url('${NationalWebThinWOFF}') format('woff');
    font-weight: 400;
  }

  @font-face {
    font-family: 'national';
    src: local('national'),
      url('${NationalWebLFRegularEOT}') format('embedded-opentype'),
      url('${NationalWebLFRegularWOFF}') format('woff'),
      url('${NationalWebLFRegularTTF}') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'national';
    src: local('national'),
      url('${NationalWebLFSemiboldEOT}') format('embedded-opentype'),
      url('${NationalWebLFSemiboldWOFF}') format('woff'),
      url('${NationalWebLFSemiboldTTF}') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'national';
    src: local('national'),
      url('${NationalWebBoldEOT}') format('embedded-opentype'),
      url('${NationalWebBoldWOFF}') format('woff'),
      url('${NationalWebBoldTTF}') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'honda-icons';
    src: local('honda-icons'),
      url('${hondaIconsEOT}') format('embedded-opentype'),
      url('${hondaIconsWOFF}') format('woff'),
      url('${hondaIconsTTF}') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Material Icons';
    src: local('honda-icons'),
      url('${MaterialIconsEOT}') format('embedded-opentype'),
      url('${MaterialIconsWOFF2}') format('woff2'),
      url('${MaterialIconsWOFF}') format('woff'),
      url('${MaterialIconsTTF}') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'HelveticaNeueLT-Bold';
    src: local('HelveticaNeueLT-Bold'),
        url('${HelveticaNeueLTBoldWOFF2}') format('woff2'),
        url('${HelveticaNeueLTBoldWOFF}') format('woff'),
        url('${HelveticaNeueLTBoldTTF}') format('truetype'),
        url('${HelveticaNeueLTBoldEOT}') format('embedded-opentype');
  }

  @font-face {
    font-family: 'HelveticaNeueLT-Roman';
    src: local('HelveticaNeueLT-Roman'),
        url('${HelveticaNeueLTRomanWOFF2}') format('woff2'),
        url('${HelveticaNeueLTRomanWOFF}') format('woff'),
        url('${HelveticaNeueLTRomanTTF}') format('truetype'),
        url('${HelveticaNeueLTRomanEOT}') format('embedded-opentype');
  }

  @font-face {
    font-family: 'HelveticaNeueLT-Light';
    src: local('HelveticaNeueLT-Light'),
        url('${HelveticaNeueLTLightWOFF2}') format('woff2'),
        url('${HelveticaNeueLTLightWOFF}') format('woff'),
        url('${HelveticaNeueLTLightTTF}') format('truetype'),
        url('${HelveticaNeueLTLightEOT}') format('embedded-opentype');
  }
`;
