import React, { createContext, useReducer } from 'react';
import { fetchRoute, clearSSRFlag, reducer as routeStateReducer } from './routeStateManager';
import {
  changeAppLanguage,
  initializeLanguage,
  reducer as languageStateReducer,
} from './languageStateManager';

// Create and export app state context for consumption in descendants.
export const AppStateContext = createContext({});

let currentState;

export function AppStateProvider({ initialState, children }) {
  const reducer = mergeReducers([routeStateReducer, languageStateReducer]);
  const [state, dispatch] = withDispatchLog(useReducer(reducer, initialState, initState), () => currentState);

  // store the currentState outside of the current function closure so that we can provide
  // a `getState()` function to our actions that will actually return the current state
  currentState = state;

  const actions = createAsyncCapableActions(
    {
      fetchRoute,
      clearSSRFlag,
      changeAppLanguage,
      initializeLanguage,
    },
    dispatch,
    () => currentState
  );

  return <AppStateContext.Provider value={{ appState: state, actions }}>{children}</AppStateContext.Provider>;
}

function initState(state) {
  // If we don't have SSR data, then set the loading flags to true so
  // that initial app rendering doesn't fail and will show loaders instead.
  if (!state.routeData || !state.routeData.fromSSR) {
    state.routeDataLoading = true;
    state.languageLoading = true;
  }
  return state;
}

// This function provides any actions that return a function with a `dispatch`
// and `state` objects that those actions can then use. This allows for multiple
// `dispatch` calls from an action or calling `dispatch` after async operations.
function createAsyncCapableActions(actions, dispatch, getState) {
  Object.keys(actions).forEach((key) => {
    const action = actions[key];

    actions[key] = (...args) => {
      const actionResult = action(...args);
      if (typeof actionResult === 'function') {
        actionResult(dispatch, getState);
      } else {
        dispatch(actionResult);
      }
    };
  });

  return actions;
}

// Simple function that accepts an array of reducer functions and "merges" them
// into a single function. The result from each reducer is passed to the next
// reducer and the final output is the return value.
function mergeReducers(reducers) {
  return (state, action) => {
    return reducers.reduce((result, reducer) => {
      result = reducer(result, action);
      return result;
    }, state);
  };
}

// This function basically wraps the `dispatch` function from a `useReducer`
// call with a console logger to log all actions that are dispatched, along
// with the updated state after the action is complete.
function withDispatchLog(useReducerResult, getState) {
  if (process.env.NODE_ENV === 'development') {
    return [
      useReducerResult[0],
      (...args) => {
        console.group('state action');
        console.log(`action '${args[0].type}'`, args[0]);

        useReducerResult[1](...args);

        console.log(`new state`, getState());
        console.groupEnd();
      },
    ];
  }
  return useReducerResult;
}
