import React from 'react';
import { ClientContext } from 'graphql-hooks';
import { ThemeProvider } from 'styled-components';

import Layout from './Layout';
import componentFactory from './componentFactory';
import { SitecoreContext } from './lib/SitecoreContext';
import { themes } from '@honda-canada/honda-sitecore-react';

// This is the main entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// wrap the app with:
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.

const AppRoot = ({ Router, graphQLClient }) => {
  return (
    <ClientContext.Provider value={graphQLClient}>
      <Router>
        <SitecoreContext componentFactory={componentFactory}>
          <ThemeProvider theme={themes.honda}>
            <Layout />
          </ThemeProvider>
        </SitecoreContext>
      </Router>
    </ClientContext.Provider>
  );
};

export default AppRoot;
