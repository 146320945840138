/* eslint-disable import/first */

import 'isomorphic-unfetch';
import { GraphQLClient } from 'graphql-hooks';
import memCache from 'graphql-hooks-memcache';

export default function(endpoint, ssr, initialCacheState) {
  const cache = memCache({
    initialState: initialCacheState,
  });

  return new GraphQLClient({
    url: endpoint,
    ssrMode: ssr,
    cache,
    fetchOptions: {
      credentials: 'include',
    },
  });
}
