import i18n from 'i18next';
import i18nInit from '../i18n';
import { resolveRouteForLocale } from './routerUtils';

export function reducer(state, action) {
  switch (action.type) {
    case 'language-init-started': {
      return { ...state, languageLoading: true };
    }
    case 'language-init-success': {
      return {
        ...state,
        languageLoading: false,
        language: action.payload,
        languageError: null,
        dictionary: null,
      };
    }
    case 'language-init-error': {
      return { ...state, languageLoading: false, languageError: action.error };
    }
    case 'language-changing': {
      return { ...state, languageLoading: false, language: action.payload };
    }
    default: {
      return state;
    }
  }
}

export function initializeLanguage(language) {
  // note: do _not_ modify `state` directly here, bad things will happen
  // you should only use it for value comparison or reference.
  // if you need to change state, use dispatch.
  return (dispatch, getState) => {
    dispatch({
      type: 'language-init-started',
    });

    const dictionary = getState().dictionary;

    // If i18n is not initialized and dictionary has a value, we assume this is
    // the initial client-side render after SSR and that the dictionary is only
    // partially complete. i.e. it only has phrases that were used in the current route.
    // Therefore, after i18n initialization is complete and state has been changed,
    // we call the `i18n.reloadResources` function to fetch the complete dictionary.
    // The full dictionary fetch _should_ be fire-and-forget, we don't need to make any
    // state changes or re-render after it is retrieved.
    const shouldLoadFullDictionaryAfterInitialization =
      !i18n.isInitialized && dictionary;

    // If i18n is already initialized, we assume a route or language change has occurred
    // and therefore call `changeLanguage`. Otherwise, initialize.
    const promise = i18n.isInitialized
      ? i18n.changeLanguage(language)
      : i18nInit(language, dictionary);

    promise
      .then(() => {
        dispatch({
          type: 'language-init-success',
          payload: language,
        });
      })
      .then(() => {
        if (shouldLoadFullDictionaryAfterInitialization) {
          // `reloadResources` returns a promise, be sure not to return it in this
          // promise chain so that the reload fetch doesn't inadvertently block any
          // state updates or rendering.
          i18n.reloadResources(language).catch((error) => console.error(error));
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: 'language-init-error',
          error,
        });
      });
  };
}

export function changeAppLanguage(newLanguage, routerContext, currentLanguage) {
  // note: do _not_ modify state directly here, bad things will happen
  // you should only use it for value comparison or reference.
  // if you need to change state, use dispatch.
  return (dispatch, getState) => {
    const appState = getState();
    const currentRoute = appState.routePath;

    if (currentLanguage !== newLanguage) {
      dispatch({
        type: 'language-changing',
        payload: newLanguage,
      });
      // no need to set language state here because the route switch will cause
      // our i18n init process to initialize with the new route language.
      const newRoute = resolveRouteForLocale(
        currentRoute,
        appState,
        newLanguage,
        currentLanguage
      );
      routerContext.history.push(newRoute);
    }
  };
}
