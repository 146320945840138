// Hides Sitecore Experience Editor markup if you run the app in connected mode
// while the Sitecore cookies are set to edit mode.

// You can also set other Sitecore-specific styles here
export const sitecore = ({ sitecoreContext }) => {
  if (sitecoreContext.pageState !== 'normal') {
    return `.scChromeData, .scpm { display: none !important; }`;
  }
  return '';
};
