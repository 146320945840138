import { dataApi } from '@sitecore-jss/sitecore-jss-react';
import { dataFetcher } from '../dataFetcher';
import config from '../temp/config';

export function reducer(state, action) {
  switch (action.type) {
    case 'clear-ssr-flag': {
      return { ...state, routeData: { ...state.routeData, fromSSR: false } };
    }
    case 'route-data-fetch-begin': {
      return { ...state, routeDataLoading: true, routePath: action.payload };
    }
    case 'route-data-fetch-success': {
      const navData =
        action.payload.sitecore.route.placeholders['hcf-header'][0].fields
          .items;
      const routeMap = JSON.parse(JSON.stringify(state.routeMap || {}));
      routeMap[action.payload.sitecore.context.language] = navData;
      return {
        ...state,
        routeDataLoading: false,
        routeData: action.payload,
        routeError: null,
        routeMap,
      };
    }
    case 'route-data-fetch-404': {
      return {
        ...state,
        routeDataLoading: false,
        routeData: action.payload,
        routeError: action.error,
        redirectUrl: '/404',
      };
    }
    case 'route-data-fetch-error': {
      return {
        ...state,
        routeDataLoading: false,
        routeData: null,
        routeError: action.error,
        redirectUrl: '/500',
      };
    }
    default: {
      return state;
    }
  }
}

export function fetchRoute(routePath, language, itemPath) {
  return (dispatch) => {
    // Signal that we're about to start fetching data. Typically use this
    // to set loading state to `true`.
    dispatch({
      type: 'route-data-fetch-begin',
      payload: routePath,
    });

    getRouteData(itemPath, language)
      .then((routeData) => {
        dispatch({
          type: 'route-data-fetch-success',
          payload: routeData,
        });
      })
      .catch((error) => {
        console.error(error);

        // 404 errors may contain context data to be used for rendering
        if (error.response && error.response.status === 404) {
          dispatch({
            type: 'route-data-fetch-404',
            error,
          });
        } else {
          dispatch({
            type: 'route-data-fetch-error',
            error,
          });
        }
      });
  };
}

export function clearSSRFlag() {
  return (dispatch) => {
    dispatch({ type: 'clear-ssr-flag' });
  };
}

/**
 * Gets route data from Sitecore. This data is used to construct the component layout for a JSS route.
 * @param {string} route Route path to get data for (e.g. /about)
 * @param {string} language Language to get route data in (content language, e.g. 'en')
 */
function getRouteData(route, language) {
  const fetchOptions = {
    layoutServiceConfig: { host: config.sitecoreApiHost },
    querystringParams: {
      sc_lang: language,
      sc_apikey: config.sitecoreApiKey,
      sc_site: config.jssAppName,
    },
    fetcher: dataFetcher,
  };

  return dataApi.fetchRouteData(route, fetchOptions);
}
